import '../App.css';

function Error() {
    return(
        <h1 className={"Error"}>
            This Page does not exist
        </h1>
    );
}

export default Error;
